import React, { useContext, useEffect, useState } from "react"
import {
	Card,
	CardBody,
	CardText,
	Col,
	Modal,
	ModalHeader,
	Row,
	ModalFooter,
	Button,
	Label,
	Input,
	ModalBody,
	Spinner,
	UncontrolledTooltip,
} from "reactstrap"
import { IntegrationCard } from "./components/IntegrationCard"
import { ButtonContainer } from "../../../../../common/Empresas/Integracoes/styles"
import AuthContext from "../../../../../../contexts/Auth"
import api from "../../../../../../services/api"
import ReactBSAlert from "react-bootstrap-sweetalert"
import EmpresaContext from "../../../../../../contexts/Empresa"
import Select from "react-select"

interface ActiveIntegration {
	id: number
	empresa_id: number
	user_id: number
	sistema_id: number
	valor: string
	is_revoked: boolean
	nome_sistema: string
	hash_sistema: string
}

interface Sistema {
	id: number
	nome: string
	tipo: string
	hash: string
	app_id?: string
	scope: string
}

export function Integracoes() {
	const { user } = useContext(AuthContext)
	const { empresaSelecionada } = useContext(EmpresaContext)
	const [activeIntegrations, setActiveIntegrations] = useState<ActiveIntegration[]>([])
	const [sistemas, setSistemas] = useState<Sistema[]>([])
	const [selectedIntegration, setSelectedIntegration] = useState<Sistema | undefined>()
	const [alert, setAlert] = useState<any>(null)
	const [modal, setModal] = useState(false)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [salesforceEmpresaIntegration, setSalesforceEmpresaIntegration] = useState<any>()

	const toggle = () => setModal(!modal)

	const getIntegrations = async () => {
		const response = await api.get(
			`esb/integracoes/ativas?types[]=oauth&userId=${user?.id}&empresaId=${empresaSelecionada?.id}`
		)

		setActiveIntegrations(response.data)
	}

	const getSalesforceEmpresaIntegration = async () => {
		const response = await api.get(
			`esb/integracoes/ativas?&types[]=text&hash=salesforce-lead-collect&empresaId=${empresaSelecionada?.id}`
		)

		setSalesforceEmpresaIntegration(response.data[0])
	}

	const getSistemas = async () => {
		const response = await api.get(`esb/integracoes/sistemas?types[]=oauth&scope=U`)

		setSistemas(response.data)
	}

	useEffect(() => {
		getIntegrations()
		getSistemas()
		getSalesforceEmpresaIntegration()
	}, [])

	const deleteIntegration = async (id: number) => {
		try {
			setIsLoading(true)

			await api.delete(`esb/integracoes/${id}`)

			getIntegrations()
			setIsLoading(false)

			setAlert(
				<ReactBSAlert
					success
					style={{ display: "block" }}
					title="Sucesso!"
					onConfirm={() => setAlert(null)}
					onCancel={() => setAlert(null)}
					confirmBtnBsStyle="primary"
					confirmBtnText="Ok"
					btnSize=""
				>
					Integração removida com sucesso!
				</ReactBSAlert>
			)
		} catch (err) {
			setIsLoading(false)

			setAlert(
				<ReactBSAlert
					error
					style={{ display: "block" }}
					title="Falha!"
					onConfirm={() => setAlert(null)}
					onCancel={() => setAlert(null)}
					confirmBtnBsStyle="primary"
					confirmBtnText="Ok"
					btnSize=""
				>
					Não foi possível remover integração!
				</ReactBSAlert>
			)

			console.log(err)
		}
	}

	function handleDeleteIntegration(integrationId: number) {
		setAlert(
			<ReactBSAlert
				warning
				style={{ display: "block" }}
				title="Tem certeza que deseja remover integração?"
				onConfirm={() => deleteIntegration(integrationId)}
				onCancel={() => setAlert(null)}
				confirmBtnBsStyle="danger"
				confirmBtnText="Sim"
				btnSize=""
				showCancel
				focusCancelBtn
			/>
		)
	}

	const customStyles = {
		control: (base: any) => ({
			...base,
			height: "calc(2.75rem + 2px)",
		}),
	}

	return (
		<>
			{alert}
			<Modal isOpen={modal} toggle={toggle}>
				<ModalHeader>Nova Integração</ModalHeader>
				<ModalBody style={{ padding: "0 1.5rem" }}>
					<span>
						<Select
							id="integration"
							className="basic-single"
							classNamePrefix="select"
							isSearchable
							styles={customStyles}
							placeholder="Selecione um portal"
							options={sistemas?.map((sistema: any) => ({
								value: sistema.id,
								label: sistema.nome,
							}))}
							onChange={(e: any) => {
								setSelectedIntegration(sistemas?.find((item) => item.id === e.value))
							}}
						/>
					</span>
				</ModalBody>
				<ModalFooter style={{ paddingTop: ".75rem" }}>
					<Button color="secondary" onClick={toggle}>
						Cancelar
					</Button>
					{selectedIntegration?.hash === "salesforce-lead-collect" ? (
						salesforceEmpresaIntegration ? (
							<Button
								color="primary"
								onClick={() => {
									window.location.href = `${salesforceEmpresaIntegration.valor}services/oauth2/authorize?response_type=code&client_id=${selectedIntegration.app_id}&redirect_uri=http://localhost:3000/admin/callback/salesforce-lead-collect/`
								}}
							>
								Integrar
								<Spinner
									style={{ marginLeft: "4px" }}
									hidden={!isLoading}
									className="mr-2"
									color="light"
									size="sm"
								/>
							</Button>
						) : (
							<>
								<Button id="salesforce-lead-collect" disabled color="primary">
									Integrar
								</Button>

								<UncontrolledTooltip placement="left-end" target={"salesforce-lead-collect"}>
									Para realizar essa integração, primeiro faça a integração do seu salesforce na
									página de integrações da empresa.
								</UncontrolledTooltip>
							</>
						)
					) : (
						<Button disabled color="primary" onClick={() => {}}>
							Integrar
						</Button>
					)}
				</ModalFooter>
			</Modal>

			<Row>
				<Col>
					<h2>Minhas Integrações</h2>

					<Row>
						<Col xs={12} sm={6} md={4} lg={3}>
							<Card
								style={{
									background: "#eee",
									height: "400px",
									border: "2px dashed",
								}}
							>
								<CardBody
									className="d-flex align-items-center justify-content-center flex-column"
									onClick={toggle}
									role="button"
									style={{ cursor: "pointer" }}
								>
									<CardText className="font-weight-bold">
										<span>
											<i className="fas fa-plus" />
										</span>
									</CardText>
									<CardText className="font-weight-bold">Nova Integração</CardText>
								</CardBody>
							</Card>
						</Col>

						{activeIntegrations?.map((integration) => {
							return (
								<IntegrationCard
									identificador="integrado"
									integration={integration}
									key={integration.id}
									onDelete={() => {
										handleDeleteIntegration(integration.id)
									}}
								/>
							)
						})}
					</Row>
				</Col>
			</Row>
		</>
	)
}
