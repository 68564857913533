import React, { Fragment, useEffect, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
} from "reactstrap";
import IMessage from "../../../entities/Meta/message";
import api from "../../../services/api";
import { MessageBubble, MessageDateContainer } from "./styles";
import { AudioPlayer } from "../AudioPlayer";
import { CheckIcon } from "../Icons/Check";

interface MessageProps extends IMessage {
  midia?: {
    midia_base64: string;
  };
}

interface MessageItemProps {
  message: MessageProps;
  isAnotherDateGroup: boolean;
  currentDate: string;
  apiMeta: boolean;
}

export default function Message({
  message,
  isAnotherDateGroup,
  currentDate,
  apiMeta,
}: MessageItemProps) {
  const [mediaURL, setMediaURL] = useState("");
  const [modal, setModal] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  const toggle = () => setModal(!modal);

  function formatTimestamp(timestamp: string | Date) {
    const date = new Date(timestamp);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  }

  async function getMediaURL() {
    setIsLoading(true); // Indica que o carregamento começou
    try {
      const {
        data: { dataUrl },
      } = await api.get(`/meta/webhooks/whatsapp/media`, {
        params: { media_id: message.media_id },
      });
      setMediaURL(dataUrl);
      sessionStorage.setItem(`@${message.media_id}`, dataUrl);
    } catch (error) {
      console.error("Failed to fetch media URL:", error);
    } finally {
      setIsLoading(false); // Carregamento finalizado
    }
  }

  useEffect(() => {
    if (apiMeta) {
      const storedMediaURL = sessionStorage.getItem(`@${message.media_id}`);

      if (storedMediaURL) {
        setMediaURL(storedMediaURL);
        return;
      }

      if (message.media_id) {
        getMediaURL();
      }
    } else {
      const { midia } = message;

      if (midia) {
        setMediaURL(midia.midia_base64);
      }
    }
  }, [message]);

  return (
    <Fragment key={message.wamid}>
      {isAnotherDateGroup && (
        <MessageDateContainer>
          <div className="date-label text-center">{currentDate}</div>
        </MessageDateContainer>
      )}

      {message.type == "image" && (
        <Modal isOpen={modal} toggle={toggle}>
          <ModalBody className="d-flex justify-content-center">
            <img style={{ maxWidth: "100%", width: "100%" }} src={mediaURL} />
          </ModalBody>
        </Modal>
      )}

      <MessageBubble
        key={message.wamid}
        $isSent={message.is_sent}
        $isWarning={!message.wamid.startsWith("wamid")}
      >
        <div className="box--message" style={{ position: "relative" }}>
          {message.type == "text" && (
            <p className="message--text">
              {!message.wamid.startsWith("wamid") && (
                <span className="mr-1" style={{ opacity: ".8" }}>
                  <i className="fas fa-exclamation-circle"></i>
                </span>
              )}
              {message.text}
            </p>
          )}
          {message.type == "image" && (
            <>
              <Dropdown
                isOpen={dropdownOpen}
                toggle={toggleDropdown}
                style={{
                  position: "absolute",
                  top: "8px",
                  right: "8px",
                }}
                direction="left"
              >
                <DropdownToggle
                  className="p-0"
                  style={{ border: "none", background: "transparent" }}
                >
                  <span className="text-muted">
                    <i className="fas fa-chevron-down"></i>
                  </span>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem>
                    <a
                      href={mediaURL}
                      download="playnee-chat-image"
                      style={{
                        textDecoration: "none",
                        color: "#131313",
                        width: "100%",
                        display: "inline-block",
                      }}
                    >
                      Download
                    </a>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
              <img
                style={{
                  maxWidth: "350px",
                  cursor: "pointer",
                  borderRadius: "2px",
                }}
                src={mediaURL}
                onClick={toggle}
              />
            </>
          )}
          {message.type == "video" && (
            <video controls style={{ maxWidth: "220px" }} src={mediaURL} />
          )}
          {message.type == "audio" && (
            <AudioPlayer audioSource={mediaURL} apiMeta={apiMeta} />
          )}
          {message.type == "sticker" && (
            <img
              style={{
                maxWidth: "190px",
                borderRadius: "6px",
              }}
              src={mediaURL}
            />
          )}
          {message.type == "document" && (
            <div className="d-flex flex-column">
              <a
                href={isLoading ? undefined : mediaURL}
                download="document"
                className={`d-flex flex-column align-items-center ${
                  isLoading ? "disabled-link" : ""
                }`}
                onClick={(e) => isLoading && e.preventDefault()}
              >
                <span style={{ fontSize: "3rem" }} className="text-muted">
                  <i
                    className={`fas fa-file ${
                      isLoading ? "text-secondary" : ""
                    }`}
                  ></i>
                </span>
                <span
                  style={{ fontSize: "1rem", alignSelf: "self-start" }}
                  className="text-muted"
                >
                  <i className="fas fa-download"></i>
                </span>
              </a>
              <small className="text-muted">
                {isLoading ? "Carregando..." : "Documento"}
              </small>
            </div>
          )}
        </div>
        <div className="box--timestamp">
          {message?.is_sent && (
            <small className="text-muted">{message?.user?.name} -</small>
          )}
          <small className="message--timestamp text-muted">
            {formatTimestamp(message.timestamps)}
          </small>
          {message?.status === "delivered" && <CheckIcon color="text-muted" />}
          {message?.status === "sent" && (
            <span className="text-muted">
              <i className="fas fa-check"></i>
            </span>
          )}
          {message?.status === "failed" && (
            <span className="text-danger">
              <i className="fas fa-times"></i>
            </span>
          )}
        </div>
      </MessageBubble>
    </Fragment>
  );
}
