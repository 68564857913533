import Login from "../pages/security/auth/SignIn"
//components do acelerando vendas
import NewPublicacao from "../pages/marketing/Publicacoes/New.js"
import EditPublicacao from "../pages/marketing/Publicacoes/Edit.js"
import Publicacoes from "../pages/marketing/Publicacoes"
import Clientes from "../pages/common/Clientes"
import NewCliente from "../pages/common/Clientes/New.js"
import EditCliente from "../pages/common/Clientes/Edit.js"
import Oportunidades from "../pages/marketing/Oportunidades"
import OportunidadesReadOnly from "../pages/marketing/Oportunidades/ReadOnly"
import NewOportunidade from "../pages/marketing/Oportunidades/New.js"
import EditOportunidade from "../pages/marketing/Oportunidades/Edit"
import PrintOportunidade from "../pages/marketing/Oportunidades/Imprimir"
import MalaDireta from "../pages/marketing/malasDiretas"
import MalaDiretaNew from "../pages/marketing/MalasDiretasNew"
import NewMalaDireta from "../pages/marketing/malasDiretas/New"
import EditMalaDireta from "../pages/marketing/malasDiretas/Edit"
import Produtos from "../pages/marketing/Produtos"
import NewProduto from "../pages/marketing/Produtos/New.js"
import EditProduto from "../pages/marketing/Produtos/Edit.js"
import Campanhas from "../pages/marketing/Campanhas"
import NewCampanha from "../pages/marketing/Campanhas/New.js"
import EditCampanha from "../pages/marketing/Campanhas/Edit.js"
import Empresas from "../pages/common/Empresas"
import NewEmpresa from "../pages/common/Empresas/New"
import EditEmpresa from "../pages/common/Empresas/Edit"
import MinhaEmpresa from "../pages/common/Empresas/MinhaEmpresa"
import Usuarios from "../pages/security/Users"
import NewUsuario from "../pages/security/Users/New"
import EditUsuario from "../pages/security/Users/Edit"
import Perfil from "../pages/security/Perfis"
import EditPerfil from "../pages/security/Perfis/Edit"
import NewPerfil from "../pages/security/Perfis/New"
import DashboardCampanhas from "../pages/Dasboards/Campanhas"
import DashboardMetas from "../pages/Dasboards/Metas"
import DashboardKpis from "../pages/Dasboards/Kpis"
import Personas from "../pages/marketing/Personas"
import NewPersona from "../pages/marketing/Personas/New"
import EditPersona from "../pages/marketing/Personas/Edit"
import PublicacoesPrioridades from "../pages/marketing/Publicacoes/Prioridades"
import Esteiras from "../pages/marketing/Esteiras"
import NewEsteira from "../pages/marketing/Esteiras/New"
import EditEsteira from "../pages/marketing/Esteiras/Edit"
import Pdca from "../pages/mentoring/Pdca"
import NewPdca from "../pages/mentoring/Pdca/Objetivo/New"
import EditPdca from "../pages/mentoring/Pdca/Objetivo/Edit"
import ListAcoesPdca from "../pages/mentoring/Acoes"
import ListAcoesPdcaNew from "../pages/mentoring/Acoesnewpage"
import Nps from "../pages/marketing/Nps"
import Calendario from "../pages/marketing/Calendario"

import PlanosEstrategicos from "../pages/mentoring/PlanosEstrategicos"
import NewPlanoEstrategico from "../pages/mentoring/PlanosEstrategicos/New"
import EditPlanoEstrategico from "../pages/mentoring/PlanosEstrategicos/Edit"

import PoliticasPrivacidade from "../pages/common/Empresas/PoliticasPrivacidade"
import NewPoliticaPrivacidade from "../pages/common/Empresas/PoliticasPrivacidade/New"
import EditPoliticaPrivacidade from "../pages/common/Empresas/PoliticasPrivacidade/Edit"

import TermosUso from "../pages/common/Empresas/TermosUso"
import NewTermosUso from "../pages/common/Empresas/TermosUso/New"
import EditTermosUso from "../pages/common/Empresas/TermosUso/Edit"

import Metas from "../pages/marketing/Metas"
import NewMeta from "../pages/marketing/Metas/New"
import EditMeta from "../pages/marketing/Metas/Edit"

import Artigos from "../pages/marketing/Artigos"
import NewArtigo from "../pages/marketing/Artigos/New.js"
import EditArtigo from "../pages/marketing/Artigos/Edit.js"

import Notas from "../pages/mentoring/Notas"
import NewNota from "../pages/mentoring/Notas/New"
import EditNota from "../pages/mentoring/Notas/Edit"

import TimelineEventos from "../pages/mentoring/TimelineEventos"

import PainelConfiguracoes from "../pages/common/Empresas/PainelConfiguracoes"

import Conversoes from "../pages/marketing/Oportunidades/Conversoes"

import ProgramaDeIndicacao from "../pages/marketing/ProgramaDeIndicacao"

import Notificacoes from "../pages/security/Notificacoes"

import Integracoes from "../pages/common/Empresas/MinhaEmpresa/Integracoes"

import Importacoes from "../pages/common/Empresas/MinhaEmpresa/Importar"

import Pagamentos from "../pages/common/Empresas/MinhaEmpresa/Pagamentos"

import Configuracoes from "../pages/common/Empresas/MinhaEmpresa/ConfiguracaoConta"

import TermosECondicoes from "../pages/common/Empresas/MinhaEmpresa/TermosECondicoes"

import PesquisaNPS from "../pages/common/Empresas/MinhaEmpresa/UrlNps"

import SitesEmpresa from "../pages/common/Empresas/MinhaEmpresa/Sites"

import ConfiguracoesVendas from "../pages/common/Empresas/MinhaEmpresa/ConfiguracoesVendas"

import Equipes from "../pages/common/Empresas/MinhaEmpresa/Equipes"

import MidiasSociais from "../pages/common/Empresas/MinhaEmpresa/MidiasSociais"

import MeusProdutos from "../pages/common/Empresas/MinhaEmpresa/MeusProdutos"

import ExternalAppCallback from "../pages/security/Callback/App"

//reports
import PerformancesColaborador from "../pages/marketing/Performance"
import ApuracaoEncerramentos from "../pages/marketing/ApuracaoEncerramentos"
import PerformancesFunil from "../pages/marketing/PerformanceFunil"
import DinamicaTrabalho from "../pages/marketing/DinamicaTrabalho"
import PerformancesPublicacao from "../pages/marketing/PerformancePublicacao"

// reports grupos-marca
import PerformancesColaboradorGrupos from "../pages/marketing/GrupoMarca/Performance"
import ApuracaoEncerramentosGrupos from "../pages/marketing/GrupoMarca/ApuracaoEncerramentos"
import PerformancesFunilGrupos from "../pages/marketing/GrupoMarca/PerformanceFunil"
import DinamicaTrabalhoGrupos from "../pages/marketing/GrupoMarca/DinamicaTrabalho"
import PerformancesPublicacaoGrupos from "../pages/marketing/GrupoMarca/PerformancePublicacao"

import Proto from "../pages/proto/proto"

import Wellcome from "../layouts/Wellcome"
import MeEdit from "../pages/security/Users/me/Edit.js"
import Chat from "../pages/marketing/Chat"
import MeuPerfil from "../pages/security/Users/me"
import ConfiguracaoAgenda from "../pages/common/Empresas/MinhaEmpresa/ConfiguracaoAgenda"
import MinhasMarcas from "../pages/common/Empresas/MinhaEmpresa/MinhasMarcas"

export const getComponent = (path) => {
	for (let p of paths) {
		if (p.path === path) {
			return p
		}
	}
	return null
}

const paths = [
	{
		path: "/minhas-marcas",
		component: MinhasMarcas,
		layout: "/admin",
	},
	{
		path: "/me",
		component: MeuPerfil,
		layout: "/admin",
	},
	{
		path: "/integracoes",
		component: Integracoes,
		layout: "/admin",
	},
	{
		path: "/calendario",
		component: Calendario,
		layout: "/admin",
	},
	{
		path: "/importacoes",
		component: Importacoes,
		layout: "/admin",
	},
	{
		path: "/pagamentos",
		component: Pagamentos,
		layout: "/admin",
	},
	{
		path: "/configuracoes-conta",
		component: Configuracoes,
		layout: "/admin",
	},
	{
		path: "/termos-de-uso",
		component: TermosECondicoes,
		layout: "/admin",
	},
	{
		path: "/pesquisa-nps",
		component: PesquisaNPS,
		layout: "/admin",
	},
	{
		path: "/sites",
		component: SitesEmpresa,
		layout: "/admin",
	},
	{
		path: "/configuracoes-vendas",
		component: ConfiguracoesVendas,
		layout: "/admin",
	},
	{
		path: "/equipes",
		component: Equipes,
		layout: "/admin",
	},
	{
		path: "/midias-sociais",
		component: MidiasSociais,
		layout: "/admin",
	},
	{
		path: "/meus-produtos",
		component: MeusProdutos,
		layout: "/admin",
	},
	{
		path: "/minha-empresa",
		component: MinhaEmpresa,
		layout: "/admin",
	},
	{
		path: "/dash_campanhas",
		component: DashboardCampanhas,
		layout: "/admin",
	},
	{
		path: "/dash_metas",
		component: DashboardMetas,
		layout: "/admin",
	},
	{
		path: "/dash_kpis",
		component: DashboardKpis,
		layout: "/admin",
	},
	{
		path: "/pdcas",
		component: Pdca,
		layout: "/admin",
	},
	{
		path: "/pdcas/new",
		component: NewPdca,
		layout: "/admin",
	},
	{
		path: "/pdcas/:id/edit",
		component: EditPdca,
		layout: "/admin",
	},
	{
		path: "/acoes",
		component: ListAcoesPdca,
		layout: "/admin",
	},
	{
		path: "/acoesnewpage",
		component: ListAcoesPdcaNew,
		layout: "/admin",
	},
	{
		path: "/planos-estrategicos",
		component: PlanosEstrategicos,
		layout: "/admin",
	},
	{
		path: "/planos-estrategicos/new",
		component: NewPlanoEstrategico,
		layout: "/admin",
	},
	{
		path: "/planos-estrategicos/:id/edit",
		component: EditPlanoEstrategico,
		layout: "/admin",
	},
	{
		path: "/esteiras/:id/edit",
		component: EditEsteira,
		layout: "/admin",
	},
	{
		path: "/esteiras/new",
		component: NewEsteira,
		layout: "/admin",
	},
	{
		path: "/esteiras",
		component: Esteiras,
		layout: "/admin",
	},
	{
		path: "/personas/:id/edit",
		component: EditPersona,
		layout: "/admin",
	},
	{
		path: "/personas/new",
		component: NewPersona,
		layout: "/admin",
	},
	{
		path: "/personas",
		component: Personas,
		layout: "/admin",
	},
	{
		path: "/performances",
		component: PerformancesColaborador,
		layout: "/admin",
	},
	{
		path: "/apuracaoencerramentos",
		component: ApuracaoEncerramentos,
		layout: "/admin",
	},
	{
		path: "/performances-funil",
		component: PerformancesFunil,
		layout: "/admin",
	},
	{
		path: "/dinamica-de-trabalho",
		component: DinamicaTrabalho,
		layout: "/admin",
	},
	{
		path: "/performances-publicacao",
		component: PerformancesPublicacao,
		layout: "/admin",
	},
	{
		path: "/performances-grupos",
		component: PerformancesColaboradorGrupos,
		layout: "/admin",
	},
	{
		path: "/apuracaoencerramentos-grupos",
		component: ApuracaoEncerramentosGrupos,
		layout: "/admin",
	},
	{
		path: "/performances-funil-grupos",
		component: PerformancesFunilGrupos,
		layout: "/admin",
	},
	{
		path: "/dinamica-de-trabalho-grupos",
		component: DinamicaTrabalhoGrupos,
		layout: "/admin",
	},
	{
		path: "/performances-publicacao-grupos",
		component: PerformancesPublicacaoGrupos,
		layout: "/admin",
	},
	{
		path: "/perfis/:id/edit",
		component: EditPerfil,
		layout: "/admin",
	},
	{
		path: "/perfis/new",
		component: NewPerfil,
		layout: "/admin",
	},
	{
		path: "/perfis",
		component: Perfil,
		layout: "/admin",
	},
	{
		path: "/usuarios/:id/edit",
		component: EditUsuario,
		layout: "/admin",
	},
	{
		path: "/usuarios/new",
		component: NewUsuario,
		layout: "/admin",
	},
	{
		path: "/usuarios",
		component: Usuarios,
		layout: "/admin",
	},
	{
		path: "/empresas/:id/edit",
		component: EditEmpresa,
		layout: "/admin",
	},
	{
		path: "/empresas/new",
		component: NewEmpresa,
		layout: "/admin",
	},
	{
		path: "/empresas",
		component: Empresas,
		layout: "/admin",
	},
	{
		path: "/wellcome",
		component: Wellcome,
		layout: "/admin",
	},
	{
		path: "/publicacoes",
		component: Publicacoes,
		layout: "/admin",
	},
	{
		path: "/publicacoes-prioridades",
		component: PublicacoesPrioridades,
		layout: "/admin",
	},
	{
		path: "/clientes",
		component: Clientes,
		layout: "/admin",
	},
	{
		path: "/oportunidades",
		component: Oportunidades,
		layout: "/admin",
	},
	{
		path: "/oportunidades-readonly",
		component: OportunidadesReadOnly,
		layout: "/admin",
	},
	{
		path: "/produtos",
		component: Produtos,
		layout: "/admin",
	},
	{
		path: "/campanhas",
		component: Campanhas,
		layout: "/admin",
	},
	{
		path: "/me/edit",
		component: MeEdit,
		layout: "/admin",
	},
	{
		path: "/publicacoes/new",
		component: NewPublicacao,
		layout: "/admin",
	},
	{
		path: "/publicacoes/:id/edit",
		component: EditPublicacao,
		layout: "/admin",
	},
	{
		path: "/clientes/new",
		component: NewCliente,
		layout: "/admin",
	},
	{
		path: "/clientes/:id/edit",
		component: EditCliente,
		layout: "/admin",
	},
	{
		path: "/oportunidades/new",
		component: NewOportunidade,
		layout: "/admin",
	},
	{
		path: "/oportunidades/:id/edit",
		component: EditOportunidade,
		layout: "/admin",
	},
	{
		path: "/oportunidades/:id/print",
		component: PrintOportunidade,
		layout: "/admin",
	},
	{
		path: "/malas-diretas",
		component: MalaDireta,
		layout: "/admin",
	},
	{
		path: "/malas-diretas-new",
		component: MalaDiretaNew,
		layout: "/admin",
	},
	{
		path: "/malas-diretas/new/:referencia_id",
		component: NewMalaDireta,
		layout: "/admin",
	},
	{
		path: "/malas-diretas/:id/edit",
		component: EditMalaDireta,
		layout: "/admin",
	},
	{
		path: "/produtos/new",
		component: NewProduto,
		layout: "/admin",
	},
	{
		path: "/produtos/:id/edit",
		component: EditProduto,
		layout: "/admin",
	},
	{
		path: "/campanhas/new",
		component: NewCampanha,
		layout: "/admin",
	},
	{
		path: "/campanhas/:id/edit",
		component: EditCampanha,
		layout: "/admin",
	},
	{
		path: "/proto",
		component: Proto,
		layout: "/admin",
	},
	{
		hidden: true,
		path: "/login",
		name: "Login",
		component: Login,
		layout: "/auth",
	},
	{
		path: "/nps",
		component: Nps,
		layout: "/admin",
	},
	{
		path: "/politicas-privacidade",
		component: PoliticasPrivacidade,
		layout: "/admin",
	},
	{
		path: "/politicas-privacidade/new",
		component: NewPoliticaPrivacidade,
		layout: "/admin",
	},
	{
		path: "/politicas-privacidade/:id/edit",
		component: EditPoliticaPrivacidade,
		layout: "/admin",
	},
	{
		path: "/termos-uso",
		component: TermosUso,
		layout: "/admin",
	},
	{
		path: "/termos-uso/new",
		component: NewTermosUso,
		layout: "/admin",
	},
	{
		path: "/termos-uso/:id/edit",
		component: EditTermosUso,
		layout: "/admin",
	},
	{
		path: "/metas",
		component: Metas,
		layout: "/admin",
	},
	{
		path: "/metas/new",
		component: NewMeta,
		layout: "/admin",
	},
	{
		path: "/metas/:id/edit",
		component: EditMeta,
		layout: "/admin",
	},
	{
		path: "/artigos",
		component: Artigos,
		layout: "/admin",
	},
	{
		path: "/artigos/new",
		component: NewArtigo,
		layout: "/admin",
	},
	{
		path: "/artigos/:id/edit",
		component: EditArtigo,
		layout: "/admin",
	},
	{
		path: "/notas",
		component: Notas,
		layout: "/admin",
	},
	{
		path: "/notas/new",
		component: NewNota,
		layout: "/admin",
	},
	{
		path: "/notas/:id/edit",
		component: EditNota,
		layout: "/admin",
	},
	{
		path: "/timeline-eventos",
		component: TimelineEventos,
		layout: "/admin",
	},
	{
		path: "/painel-configuracoes",
		component: PainelConfiguracoes,
		layout: "/admin",
	},
	{
		path: "/conversoes",
		component: Conversoes,
		layout: "/admin",
	},
	{
		path: "/programa-de-indicacao",
		component: ProgramaDeIndicacao,
		layout: "/admin",
	},
	{
		path: "/notificacoes",
		component: Notificacoes,
		layout: "/admin",
	},
	{
		path: "/conversas",
		component: Chat,
		layout: "/admin",
	},
	{
		path: "/configuracoes-agenda",
		component: ConfiguracaoAgenda,
		layout: "/admin",
	},
	{
		path: "/callback/:app",
		component: ExternalAppCallback,
		layout: "/admin",
	},
]
